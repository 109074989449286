$(function() {

    // Navbar toggle
    $(document).on('click', '.navbar-trigger', function() {
        if ($('.navbar-trigger').hasClass('is-active')) {
            $('.navbar-trigger').removeClass('is-active');
            $('#navbarResponsive').removeClass('show');
        } else {
            $('.navbar-trigger').addClass('is-active');
            $('#navbarResponsive').addClass('show');
        }
    });

    // Navbar link close navbar
    $('#navbarResponsive .page-scroll').click(function() {
        if ($('.navbar-trigger').hasClass('is-active')) {
            $('.navbar-trigger').removeClass('is-active');
            $('#navbarResponsive').removeClass('show');
        }
    });

    // Navbar close by click anywhere except navbar and trigger
    $(document).click(function(e) {
        if ($(e.target).closest('#navbarResponsive').length === 0 && $(e.target).closest('.navbar-trigger').length === 0) {
            $('.navbar-trigger').removeClass('is-active');
            $('#navbarResponsive').removeClass('show');
        }
    });

    // Page scrolling feature - requires jQuery and jQuery Easing plugin
    $('a.page-scroll').bind('click', function(event) {
        if ($($(this).attr('href')).length) {
            $('html, body').stop().animate({
                scrollTop: $($(this).attr('href')).offset().top
            }, 1500, 'easeInOutExpo');
        }
        event.preventDefault();
    });

    // Scroll to top button show/hide
    $(window).scroll(function () {
        if ($(this).scrollTop() > $( window ).height() - 1) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });

    // Scroll Indicator
    window.onscroll = function() {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById('scrollProgress').style.width = scrolled + '%';
    }

    // Scroll Reaveal
    window.sr = ScrollReveal({ reset: true });

    sr.reveal('header h1', { duration: 200 });
    sr.reveal('header p', { duration: 400 });
    sr.reveal('#scrollDownLink', { duration: 800 });

    sr.reveal('section .text-center > .text-center > h2', { duration: 200 });
    sr.reveal('section .text-center > h3', { duration: 300 });
    sr.reveal('section .text-center > h4', { duration: 400 });
    sr.reveal('section .text-center > p', { duration: 600 });
    sr.reveal('section .text-center > ul', { duration: 400 });

    sr.reveal('.under-cover .btn', { duration: 800 });
    sr.reveal('.footer-logo-link', { duration: 800 });

    // Shuffle instance
    var Shuffle = window.Shuffle;

    var shuffleInstance = new Shuffle(document.querySelector('.work-container'), {
        itemSelector: '.work-item',
        sizer: '.sizer',
        buffer: 1,
    });

    // Filter category with Shuffle
    $('body').on('click', '.filter-category', function(e) {
        e.preventDefault();

        $('.filter-category').removeClass('font-weight-bold active');
        $(this).addClass('font-weight-bold active');

        var category = $(this).attr('href').substr(1);

        if (category) {
            // $('.work-item').hide();
            if (category == 'all') {
                shuffleInstance.filter();
                // $('.work-item').show();
            } else {
                shuffleInstance.filter(category);
                // $('.work-item.'+category).show();
            }
        }
    });

    // Embed link
    $('body').on('click', '.embed-link', function(e) {
        e.preventDefault();

        $(this).parent().addClass('py-0');
        $(this).parent().find('.btn').addClass('d-none');
        $(this).next().removeClass('d-none');

        // Autoplay
        let iframe = $(this).next().find('iframe');
        let connector = (iframe.attr('src').indexOf('?') > -1) ? '&' : '?';
        iframe.attr('src', iframe.attr('src') + connector + 'autoplay=1');
    });

    // Slick slider carousel
    $('.slick-slider-carousel').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        ]
    });

    // Slick slider fader
    $('.slick-slider-fader').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: false,
        infinite: true,
        pauseOnHover: false,
        speed: 1000,
        fade: true,
        cssEase: 'ease'
    });

    // Ajax contact form
    var form = $('#getInTouchForm');
    var removeDelay = 5000; // 5s
    form.submit(function(e) {
        // prevent form submission
        e.preventDefault();

        // visualize ajax loading...
        $('body *').css('cursor', 'wait');

        // submit the form via Ajax
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            dataType: 'html',
            data: form.serialize(),
            success: function(result) {
                // clear previous message
                $('.notices', form).remove();
                // prepend the result in the HTML
                form.prepend(result);
                // clear inputs
                $('input, textarea', form).val('');
                // remove message with delay
                setTimeout(function(){
                    $('.notices', form).slideUp(function(){
                        $('.notices', form).remove();
                    });
                }, removeDelay);
            },
            complete: function() {
                // remove visualize ajax loading
                $('body *').css('cursor', '');
            }
        });
    });

});
